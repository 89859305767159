@use "~styles/colors" as *;
@use "~styles/themes" as *;

.theme-switch {
  position: relative;
  background-color: color(light);
  display: flex;
  border-radius: 2.1rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0.4rem;
  cursor: pointer;

  &::before {
    background-color: color(black1);
    width: calc(50% - 0.4rem);
    height: calc(100% - 0.4rem * 2);
    position: absolute;
    margin: 0.4rem 0;
    content: "";
    border-radius: 2.1rem;
    z-index: 0;
    @include themify {
      left: themed("themeSwitchPosition");
    }
  }

  &__button {
    padding: 0 1rem;
    height: 2.6rem;
    border-radius: 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.6rem;
    z-index: 1;

    > svg {
      height: 100%;
    }

    &--active {
      > svg {
        height: 80%;
      }
    }

    &--light {
      > svg {
        @include themify {
          stroke: themed("themeSwitchLightItem");
        }
      }
    }

    &--dark {
      > svg {
        @include themify {
          fill: themed("themeSwitchDarkItem");
          stroke: themed("themeSwitchDarkItem");
        }
      }
    }
  }
}
