@use "~styles/breakpoint" as *;
@use "~styles/themes" as *;

.header {
  @include themify {
    background-color: themed("headerBG");
    color: themed("backgroundColor");
  }

  box-sizing: border-box;
  border-radius: 3.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 6rem 0;
  user-select: none;
  overflow: hidden;

  @include breakpoint($desktop) {
    padding: 2rem 0;
  }

  @include breakpoint($mobile) {
    border-radius: 2rem;
    padding: 6rem 1rem;
  }

  &__header {
    margin: 1.4rem 0;

    @include breakpoint($desktop) {
      margin: 0;
    }
  }

  &__description {
    max-width: 87.6rem;
    text-align: center;
    margin-bottom: 5rem;

    @include breakpoint($desktop) {
      margin-bottom: 4rem;
      max-width: 52rem;
    }

    @include breakpoint($mobile) {
      margin-bottom: 4rem;
      max-width: 25rem;
    }
  }
}
