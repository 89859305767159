$font-causten-round: "causten_round";
$font-pt: "pt";

@font-face {
  font-family: $font-causten-round;
  src: url("../../public/fonts/caustenround-bold-webfont.woff2") format("woff2"),
    url("../../public/fonts/caustenround-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: $font-causten-round;
  src: url("../../public/fonts/caustenround-regular-webfont.woff2") format("woff2"),
    url("../../public/fonts/caustenround-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-pt;
  src: url("../../public/fonts/pt_serif-webfont.woff2") format("woff2"),
    url("../../public/fonts/pt_serif-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-pt;
  src: url("../../public/fonts/pt_serif_italic-webfont.woff2") format("woff2"),
    url("../../public/fonts/pt_serif_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
