@use "~styles/breakpoint" as *;
@use "~styles/colors" as *;
@use "~styles/mixins" as *;
@use "~styles/themes" as *;

$padding: 4.8rem;
$padding-desktop: 3.3rem;

.footer {
  $self: &;

  width: 100%;
  font-weight: bold;
  margin-bottom: 5rem;

  &__info {
    position: relative;
    color: color(black1);
    font-size: 2.2rem;
    line-height: 2.6rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: $padding;
    margin-bottom: 2.6rem;
    box-sizing: border-box;
    border-radius: 3.4rem;
    min-height: 20.6rem;

    @include themify {
      background-color: themed("footerBackground");
    }

    @include breakpoint($desktop) {
      flex-direction: column;
      padding: $padding-desktop;
      font-size: 2rem;
      line-height: 3rem;
      min-height: auto;
      margin-bottom: 3rem;
    }

    @include breakpoint($mobile) {
      font-size: 1.8rem;
      line-height: 3rem;
      padding: 2rem;
      border-radius: 2rem;
    }
  }

  &__link {
    display: flex;
    column-gap: 1rem;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
    border-radius: 1rem;
    margin: -1rem 0;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    @include transition-basic(background-color);

    &:hover {
      background-color: color(select);

      #{ $self }__icon {
        fill: color(light);
      }
    }

    &--active {
      font-weight: normal;
      background-color: color(green1);
      #{ $self }__icon {
        fill: color(light);
      }

      &:hover {
        background-color: color(green1);
      }
    }

    &--email {
      width: 31.2rem;
    }

    &--phone {
      width: 20rem;
    }

    &--nip {
      width: 20.8rem;
    }

    @include breakpoint($desktop) {
      display: inline-block;
      width: auto;
      margin: 0.5rem 0 0;
      margin-left: -1rem;
    }
  }

  &__icon {
    @include transition-basic;
    fill: color(select);

    @include breakpoint($desktop) {
      margin-left: 0.5rem;
    }
  }

  &__name {
    font-size: 1.4rem;
    padding: 0 $padding;
    text-align: right;
    @include themed-font;

    @include breakpoint($desktop) {
      padding-left: $padding-desktop;
    }

    @include breakpoint($mobile) {
      padding-left: 2rem;
    }
  }

  &__copy {
    vertical-align: super;
    font-size: 1.2rem;
  }

  &__year {
    position: absolute;
    left: $padding;
    bottom: 2.7rem;

    @include breakpoint($desktop) {
      right: $padding-desktop;
      bottom: $padding-desktop;
    }

    @include breakpoint($mobile) {
      display: none;
    }
  }
}
