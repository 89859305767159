@use "~styles/breakpoint" as *;
@use "~styles/colors" as *;
@use "~styles/mixins" as *;
@use "~styles/themes" as *;

@keyframes tile-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tile {
  position: relative;
  border-radius: 3.4rem;
  padding: 2.3rem 3.3rem;
  animation-name: tile-animation;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @include transition-medium(transform);

  &:hover {
    transform: scale(1.02);
  }

  @include breakpoint($mobile) {
    padding: 2rem 1.6rem;
    border-radius: 2rem;
  }

  &--white {
    background-color: color(light);
  }

  &--green {
    background-color: color(green1);
  }

  &--purple {
    background-color: color(purple1);
  }

  &--purplegrey {
    background-color: color(purple1);

    *:not(h2) {
      color: color(gray1);
    }
  }

  &--orange {
    background-color: color(orange1);
  }

  &--grey {
    background-color: color(gray1);
  }

  &--gray {
    @include themify {
      background-color: themed("grayColor");
    }
  }

  &--silver {
    background-color: color(silver1);
  }

  &--light {
    @include themify {
      color: themed("lightFontColor");
      background-color: themed("lightColor");
    }
  }

  &--dotted {
    padding-top: 2.8rem;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 2.1rem;
      right: 2.1rem;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
      @include themify {
        background-color: themed("backgroundColor");
      }
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    margin: 0 auto 1.7rem;

    &--overflow {
      margin-top: -7rem;

      @include breakpoint($tablet) {
        margin-top: -5.5rem;
      }
    }

    &--margin {
      margin: -4rem auto -8rem;

      @include breakpoint($tablet) {
        margin: -2rem auto -4rem;
      }
    }

    &--margintop {
      margin-top: -3rem;

      @include breakpoint($tablet) {
        margin-top: -2rem;
      }
    }

    &--small {
      max-width: 80%;
    }

    @include breakpoint($tablet) {
      max-width: 20rem;
    }
  }
}
