@use "~styles/breakpoint" as *;

.masonry {
  $self: &;

  display: flex;

  &--loading {
    overflow: hidden;
    height: 0;
    min-height: 100vh;

    #{ $self }__column {
      opacity: 0;
    }
  }

  &__column {
    flex: 1;
    padding-left: 3rem;

    @include breakpoint($desktop) {
      padding-left: 2rem;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  &__element {
    padding-top: 3rem;

    @include breakpoint($desktop) {
      padding-top: 2rem;
    }

    @include breakpoint($mobile) {
      padding-top: 2rem;
    }

    &:first-child {
      padding-top: 0;
    }
  }
}
