@use "~styles/colors" as *;
@use "sass:map";

$themes: (
  light: (
    backgroundColor: #efe7e3,
    headerBG: #423d3b,
    fontColor: color(black1),
    lightColor: #423d3b,
    lightFontColor: color(light),
    grayColor: color(light),
    borderColor: color(black1),
    footerBackground: color(gray1),
    themeSwitchButton: color(light),
    themeSwitchLightItem: color(light),
    themeSwitchDarkItem: color(silver1),
    themeSwitchPosition: 0.4rem,
  ),
  dark: (
    backgroundColor: color(black1),
    headerBG: color(silver1),
    fontColor: color(silver1),
    lightColor: #f0e7e4,
    lightFontColor: color(black1),
    grayColor: #f0e7e4,
    borderColor: color(light),
    footerBackground: #f0e7e4,
    themeSwitchLightItem: color(silver1),
    themeSwitchDarkItem: color(light),
    themeSwitchPosition: 50%,
  ),
);

@mixin themify($transition: true) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key, $submap in $map {
        $value: map.get(map.get($themes, $theme), "#{$key}");
        $theme-map: map.merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;

      @if $transition == true {
        transition: all 120ms linear;
      }

      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map.get($theme-map, $key);
}

@mixin themed-font() {
  @include themify {
    color: themed("fontColor");
  }
}
